import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Data = [
  {
    image: "/icon/brands/telechargement-1.png",
    alt: "rge",
  },
  {
    image: "/icon/brands/cee.jpg",
    alt: "rge",
  },
  {
    image: "/icon/brands/Unknown.png",
    alt: "rge",
  },
  {
    image: "/icon/brands/agence.jpg",
    alt: "rge",
  },
  {
    image: "/icon/brands/eee.jpeg",
    alt: "rge",
  },
];

const BrandItemNew = ({ mediaWith }) => {
  const { height, width } = useWindowDimensions();

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        {Data.map((data, index) => (
          <div key={index}>
            {width >= mediaWith ? (
              <img key={index} style={{ height: 150, width: 180 }} src={process.env.PUBLIC_URL + data.image} alt={data.alt} />
            ) : null}
          </div>
        ))}
      </div>
    </>
  );
};

export default BrandItemNew;
