import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import FsLightbox from "fslightbox-react";
import SectionTitle from "../../elements/section-title/SectionTitle";

const VideoTwo = () => {
  const [toggler, setToggler] = useState(false);

  return (
    <>
      <div id="primes-video" className="section section-padding-equal" style={{ padding: "60px 0px 60px 0" }}>
        <div className="container">
          <SectionTitle
            subtitle="Coup de Pouce pilotage connecté du chauffage pièce par pièce"
            title="Obtenez une prime jusqu'à 624 €"
            description="VOS TRAVAUX ECO collabore avec le gouvernement dans le cadre du programme « Coup de pouce Pilotage connecté du chauffage pièce par pièce »."
            textAlignment=""
            textColor=""
          />
          <div className="about-expert">
            <div className="thumbnail">
              <img src={process.env.PUBLIC_URL + "/images/banner/111.jpg"} alt="Thumbnail" />
              <div className="popup-video">
                <button className="play-btn" onClick={() => setToggler(!toggler)}>
                  <FaPlay />
                </button>
              </div>
            </div>
          </div>
        </div>
        <ul className="shape-group-16 list-unstyled">
          <li className="shape shape-1">
            <img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" />
          </li>
          <li className="shape shape-2">
            <img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" />
          </li>
          <li className="shape shape-3">
            <img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" />
          </li>
        </ul>
      </div>
      <FsLightbox
        toggler={toggler}
        sources={[
          <video className="fslightbox-source fslightbox-video fslightbox-opacity-1"  autoplay="true"  style={{ width: '706px', height: '397.125px' }}>
            <source src={process.env.PUBLIC_URL + '/images/thermostat.mp4'} type="video/mp4" />
          </video>
        ]}
      />    </>
  );
};

export default VideoTwo;
