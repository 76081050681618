import React from "react";

const Denied = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={process.env.PUBLIC_URL + "/icon/house/sad.png"}
        alt="Icon"
        style={{
          height: 60,
          borderRadius: 50,
        }}
      />
      <br></br>
      <br></br>

      <h4>Aïe… Votre projet n’est pas éligible aux aides à la rénovation.</h4>

      <span>
        Ces aides sont réservées aux logements construits depuis plus de 2 ans
        et aux propriétaires
      </span>
      <br></br>
    </div>
  );
};

export default Denied;
