import React, { useState } from "react";
import { DELAY_ANIMATION_CHECKBOX } from "../../utils/conf";
import {
  BOIS_FIELD,
  ELECTRICITY_FIELD,
  FIOUL_FIELD,
  GAZ_FIELD,
  OTHERS_FIELD,
  POMPE_CHALEUR_FIELD,
  TYPE_OF_ENERGY,
} from "../../utils/constants";

import CheckIconSmall from "../checkIconSmall/CheckIconSmall";

const EnergyType = ({ getValues, setValue, page, setPage }) => {
  const [object, setObject] = useState({
    [FIOUL_FIELD]: false,
    [ELECTRICITY_FIELD]: false,
    [GAZ_FIELD]: false,
    [BOIS_FIELD]: false,
    [POMPE_CHALEUR_FIELD]: false,
    [OTHERS_FIELD]: false,
  });
  const handleChange = (e, text) => {
    setObject((prevState) => {
      let result = prevState;
      Object.keys(result).forEach(function (key, index) {
        if (key === text) {
          result = { ...result, [key]: true };
        } else result = { ...result, [key]: false };
      });
      return result;
    });
    setValue(TYPE_OF_ENERGY, text);
    setTimeout(() => {
      setPage(page + 1);
    }, DELAY_ANIMATION_CHECKBOX);
  };
  return (
    <>
      <h5 className="center">
        Quelle est l’énergie de chauffage actuelle de votre logement ?
      </h5>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <div style={{}}>
          <CheckIconSmall
            isCheck={
              object[ELECTRICITY_FIELD] ||
              getValues(TYPE_OF_ENERGY) === ELECTRICITY_FIELD
            }
            iconPath={process.env.PUBLIC_URL + "/icon/house/electricity.png"}
            text={ELECTRICITY_FIELD}
            handleChange={handleChange}
          />
          <CheckIconSmall
            isCheck={
              object[FIOUL_FIELD] || getValues(TYPE_OF_ENERGY) === FIOUL_FIELD
            }
            iconPath={process.env.PUBLIC_URL + "/icon/house/eau.png"}
            text={FIOUL_FIELD}
            handleChange={handleChange}
          />
        </div>
        <div>
          {/* <CheckIconSmall
            isCheck={object[BOIS_FIELD]}
            iconPath={process.env.PUBLIC_URL + "/icon/house/bonfire.png"}
            text={BOIS_FIELD}
            handleChange={handleChange}
          /> */}
          {/* <CheckIconSmall
            isCheck={object[POMPE_CHALEUR_FIELD]}
            iconPath={process.env.PUBLIC_URL + "/icon/house/cooler.png"}
            text={POMPE_CHALEUR_FIELD}
            handleChange={handleChange}
          /> */}
          <CheckIconSmall
            isCheck={
              object[GAZ_FIELD] || getValues(TYPE_OF_ENERGY) === GAZ_FIELD
            }
            iconPath={process.env.PUBLIC_URL + "/icon/house/gasBlue.png"}
            text={GAZ_FIELD}
            handleChange={handleChange}
          />
          <CheckIconSmall
            isCheck={
              object[OTHERS_FIELD] || getValues(TYPE_OF_ENERGY) === OTHERS_FIELD
            }
            iconPath={process.env.PUBLIC_URL + "/icon/house/candle2.png"}
            text={OTHERS_FIELD}
            handleChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};
export default EnergyType;
