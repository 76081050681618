import React from "react";

let styles = {
  display: "flex",
  justifyContent: "center",
  borderRadius: "10px",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  placeContent: "space-evenly",
  position: "relative",
  border: "solid 2px",
  boxShadow: "0 4px 20px #00325021",
  borderRadius: 10,
  height: "100%",
  width: "100%",
  backgroundColor: "white",
  padding: 10,
};
const BorderCircle = () => {
  return (
    <div
      style={{
        margin: 5,
        position: "absolute",
        right: 0,
        top: 0,
        height: 20,
        width: 20,
      }}
    ></div>
  );
};
const IconCheck = () => {
  return (
    <img
      src={process.env.PUBLIC_URL + "/icon/house/check.png"}
      alt="Icon"
      style={{
        margin: 4,
        position: "absolute",
        right: 0,
        top: 0,
        height: 22,
      }}
    />
  );
};

const CheckIcon = ({ isCheck, iconPath, text, style, handleChange }) => {
  return (
    <div
      style={{
        height: 135,
        width: 135,
      }}
    >
      <div
        onClick={(e) => handleChange(e, text)}
        style={{
          ...styles,
          ...style,
        }}
      >
        {isCheck ? <BorderCircle /> : null}
        {isCheck ? <IconCheck /> : null}
        <img src={iconPath} alt="Icon" style={{ height: 70 }} />
        <span>{text}</span>
      </div>
    </div>
  );
};
export default CheckIcon;
