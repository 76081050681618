import React, { useState } from "react";
import { APPART_FIELD, HOUSE_FIELD, MAPPING_FIELDS } from "../../utils/constants";
import Card from "../card/Card";
import CheckIcon from "../checkIcon/CheckIcon";

const Step1 = ({ setValue, getValues, setPage, page }) => {
  const [object, setObject] = useState({
    [HOUSE_FIELD]: true,
    [APPART_FIELD]: false,
  });

  const handleChange = (e, text) => {
    if (text === APPART_FIELD) {
      setPage("Denied");
      return;
    }

    let property = MAPPING_FIELDS[text];
    setValue(property, text);
    setPage(page + 1);
  };
  return (
    <Card title={"Vérifier votre éligibilité"} subTitle={"sans engagement"} titleStyle={{ color: "rgb(89, 86, 233)", textAlign: "center" }}>
      <CheckIcon
        isCheck={getValues("housingType") === HOUSE_FIELD ? true : object[HOUSE_FIELD]}
        iconPath={process.env.PUBLIC_URL + "/icon/house/home.png"}
        text={HOUSE_FIELD}
        handleChange={handleChange}
      />

      {/* BULDING */}

      <CheckIcon
        isCheck={object[APPART_FIELD]}
        iconPath={process.env.PUBLIC_URL + "/icon/house/office-building.png"}
        text={APPART_FIELD}
        handleChange={handleChange}
      />
    </Card>
  );
};
export default Step1;
