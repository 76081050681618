import React from "react";
import { HiOutlineLightBulb } from "react-icons/hi";
import { PRIMARY } from "../../assets/colors";
import { BsHouseDoor } from "react-icons/bs";
import { MdPower } from "react-icons/md";
import { GiWorld } from "react-icons/gi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const styles = {
  display: "flex",
  placeContent: "space-between",
  alignItems: "center",
};
const iconStyles = {
  width: "2.5em",
  height: "2.5em",
  //   border: "solid 2px",
  //   borderRadius: 40,
};

const Data = [
  {
    text: "Économisez jusqu'à 30% sur vos factures d'énergie avec un thermostat connecté.",
    component: <HiOutlineLightBulb height={500} width={500} color={PRIMARY} style={{ ...iconStyles }} />,
  },
  {
    text: "Contrôlez votre chauffage à distance via une application mobile.",
    component: <MdPower height={500} width={500} color={PRIMARY} style={{ ...iconStyles }} />,
  },
  {
    text: "Installation facile et rapide par nos experts certifiés.",
    component: <BsHouseDoor color={PRIMARY} style={{ ...iconStyles }} />,
  },
  {
    text: "Réduisez votre empreinte carbone avec une gestion intelligente de l'énergie.",
    component: <GiWorld color={PRIMARY} style={{ ...iconStyles }} />,
  },
];

const CardIconList = ({}) => {
  const { height, width } = useWindowDimensions();

  return (
    <>
      {width <= 992 ? (
        <div>
          {Data.map((item, i) => (
            <div key={i} style={{ ...styles }}>
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item.component}
              </div>
              <span style={{ width: "90%", paddingLeft: 10 }}>{item.text}</span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default CardIconList;
