import React from "react";

const SectionTitle = ({
  subtitle,
  title,
  description,
  textAlignment,
  textColor,
}) => {
  return (
    <div className={`section-heading ${textAlignment} ${textColor}`}>
      {/* <div
        className="subtitle"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></div> */}
      <h3 className="" dangerouslySetInnerHTML={{ __html: title }}></h3>
      <p dangerouslySetInnerHTML={{ __html: description }}></p>
    </div>
  );
};

export default SectionTitle;
