import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

// Home Pages Import
import HomeStartup from "./pages/HomeStartup";

// Pages
import ErrorPage from "./pages/404";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import { Analytics } from "@vercel/analytics/react";
import TagManager from "react-gtm-module";

// Css Import
import "./assets/scss/app.scss";

const tagManagerArgs = {
  gtmId: "GTM-TBSQGXN",
  dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  return (
    <Router>
      <Analytics />

      <ScrollToTop>
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<HomeStartup />} />

          <Route path={process.env.PUBLIC_URL + "/pompe"} element={<HomeStartup />} />

          <Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />} />

          <Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
