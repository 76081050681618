import React, { useState, useEffect } from "react";
import Card from "../card/Card";

const InstalationType = ({ setValue, setPage, getValues, page }) => {
  const [selectedSurface, setSelectedSurface] = useState(getValues("housingSurface") || "");

  const options = [
    "< 35 m²",
    "35 à 60 m²",
    "60 à 70 m²",
    "70 à 90 m²",
    "90 à 110 m²",
    "110 à 130 m²",
    "Plus que 130 m²",
  ];

  const handleChange = (e) => {
    const text = e.target.value;
    setSelectedSurface(text);
   
      setValue("housingSurface", text);
      setPage(page+1)
  };

  useEffect(() => {
    setValue("housingSurface", selectedSurface);
  }, [selectedSurface, setValue]);

  return (
    <>
      <Card title={"La surface habitable de votre logement ?"} subTitle={""}>
        <select onChange={handleChange} value={selectedSurface}>
          <option value="" disabled>
            Sélectionner une surface
          </option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </Card>
    </>
  );
};

export default InstalationType;
