import React, { useState } from "react";
import { DELAY_ANIMATION_CHECKBOX } from "../../utils/conf";
import {
  BETWEEN_TWO_FIFTEEN_FIELD,
  MAPPING_FIELDS,
  MINUS_TWO_YEARS_FIELD,
  MORE_FIFTEEN_FIELD,
  NO,
  YES,
} from "../../utils/constants";
import CardListItem from "../card/CardListItem";

const AgeOfHouse = ({ formData, getValues, setValue, setPage, page }) => {
  const [object, setObject] = useState({
    [MINUS_TWO_YEARS_FIELD]: false,
    [BETWEEN_TWO_FIFTEEN_FIELD]: false,
    [MORE_FIFTEEN_FIELD]: false,
  });
  const handleChange = (e, text) => {
    if (text === MINUS_TWO_YEARS_FIELD) {
      setPage("Denied");
      return;
    } else {
      setObject((prevState) => {
        let result = prevState;
        Object.keys(result).forEach(function (key, index) {
          if (key === text) {
            result = { ...result, [key]: true };
          } else result = { ...result, [key]: false };
        });
        return result;
      });
      setValue(MAPPING_FIELDS[text], text);
      setTimeout(() => {
        setPage(page + 1);
      }, DELAY_ANIMATION_CHECKBOX);
    }
  };
  return (
    <>
      <h5 className="center">
      Votre logement a-t-il été construit il y a plus de 2 ans ?
      </h5>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardListItem
          myKey={YES}
          text={YES}
          iconPath={process.env.PUBLIC_URL + "/icon/house/calendar2.png"}
          isCheck={getValues("ageOfHouse") === YES}
          handleChange={handleChange}
        />
        <CardListItem
          myKey={NO}
          text={NO}
          iconPath={process.env.PUBLIC_URL + "/icon/house/calendar2.png"}
          isCheck={getValues("ageOfHouse") === NO}
          handleChange={handleChange}
        />

      </div>
    </>
  );
};
export default AgeOfHouse;
