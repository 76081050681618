import React, { useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import Step1 from "./Step1";
import AgeOfHouse from "./AgeOfHouse";
import EnergyType from "./EnergyType";
import OwnerOrTenant from "./OwnerOrTenant";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Denied from "./Denied";
import { get, isEmpty, join } from "lodash";
import ZipCode from "./ZipCode";
import {
  FIRST_NAME,
  LAST_NAME,
  SEXE,
  OWNER_STATUS_TYPE,
  POSTAL_CODE,
  INSTALATION_TYPE,
  HOUSING_TYPE,
  AGE_OF_HOUSE_TYPE,
  TYPE_OF_ENERGY,
  PHONE_TYPE,
} from "../../utils/constants";
import FinalStep from "./FinalStep";
import { BsArrowLeft } from "react-icons/bs";
import InstalationType from "./InstalationType";
import axios from "axios";

const FORMS_MAPPER = {
  0: {
    errorMessage: "Veuillez faire une selection de votre projets",
    property: HOUSING_TYPE,
    defaultValue: "Maison",
  },
  1: {
    errorMessage: "Veuillez faire une selection votre logement",
    property: OWNER_STATUS_TYPE,
    defaultValue: "Propriétaire",
  },
  2: {
    errorMessage: "Veuillez faire une selection de votre projets",
    property: INSTALATION_TYPE,
    defaultValue: [],
  },
  3: {
    errorMessage: "Veuillez faire une selection une date",
    property: AGE_OF_HOUSE_TYPE,
    defaultValue: "Entre 2 ans et 15 ans",
  },
  4: {
    errorMessage: "Veuillez faire une selection le type d’énergie",
    property: TYPE_OF_ENERGY,
    defaultValue: "",
  },
  5: {
    errorMessage: "Veuillez saisir votre code postal au bon format",
    property: POSTAL_CODE,
    defaultValue: "",
  },
  6: {
    errorMessage: "Veuillez remplir tout les champs",
    property: [SEXE, LAST_NAME, FIRST_NAME],
    defaultValue: "",
  },
  7: {
    errorMessage: "Veuillez saisir un numero au bon format",
    property: PHONE_TYPE,
    defaultValue: "",
  },
};

const FormMain = () => {
  const { handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      housingType: "Maison",
    },
  });
  const [errors, setErrors] = useState("");
  const [page, setPage] = useState(0);
  const stepLength = Object.keys(FORMS_MAPPER).length;

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return <Step1 {...{ setPage, setValue, getValues, page }} />;
      case 1:
        return <OwnerOrTenant {...{ setPage, setValue, getValues, page }} />;
      case 2:
        return <InstalationType {...{ setPage, setValue, getValues, page }} />;
      case 3:
        return <AgeOfHouse {...{ setPage, setValue, getValues, page }} />;
      case 4:
        return <EnergyType {...{ setPage, setValue, getValues, page }} />;
      case 5:
        return <ZipCode {...{ setPage, setValue, getValues, page }} />;
      case 6:
        return <Step6 {...{ setPage, setValue, getValues, page }} />;
      case 7:
        return <Step7 {...{ setPage, setValue, getValues, page }} />;
      case 8:
        return <FinalStep {...{ setPage, setValue, getValues, page }} />;
      case "Denied":
        return <Denied setPage={setPage} setValue={setValue} getValues={getValues} />;
      default:
        return null;
    }
  };

  const onSubmit = (data) => console.log(data);

  const sendEmail = async (event) => {
    event.preventDefault();
    setErrors(null);
    if (checkErrors()) {
      setErrors(checkErrors());
    } else {
      try {
        const { data } = await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key=ce0de992545c4e1ca8ce4a5aa93abb49");
        const dataForms = getValues();
        const dataToSubmit = { ...dataForms, userInfos: JSON.stringify(data) };
        dataToSubmit.instalationType = join(dataForms.instalationType, ", ");

        await axios.post("173.249.0.214:5000/api/enregistrer-donnees", dataToSubmit);
        TagManager.dataLayer({
          dataLayer: { forms: dataForms },
          dataLayerName: "forms",
        });
      } catch (error) {
        console.error(error);
        emailjs.send("service_ivs928i", "template_ay4qnr1", { error: "ERROR" }, "0nFyifjJjQTjYnu71");
        emailjs.send("service_ivs928i", "template_ay4qnr1", { error: JSON.stringify(error) }, "0nFyifjJjQTjYnu71");
      }
    }
  };

  const _next = (event) => {
    event.preventDefault();
    setErrors(null);
    if (checkErrors()) {
      setErrors(checkErrors());
    } else {
      const currentStep = page >= stepLength - 1 ? stepLength : page + 1;
      setPage(currentStep);

      if (stepLength - 1 === page) {
        sendEmail(event);
      }
    }
  };

  const _prev = () => {
    let currentStep = page;
    if (currentStep === "Denied") {
      setPage(1);
      return;
    }
    currentStep = currentStep <= 0 ? 0 : currentStep - 1;
    setPage(currentStep);
  };

  const checkErrors = () => {
    const mapper = FORMS_MAPPER[page].property;
    const error = FORMS_MAPPER[page].errorMessage;
    const zipCodeValue = getValues(POSTAL_CODE);
    const phoneValue = getValues("phone");
    const regexZipCode = /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/;
    const regexPhoneNumber = /^[0-9]{10}$/;
    const isValidZipCode = regexZipCode.test(zipCodeValue);
    const isValidPhone = regexPhoneNumber.test(phoneValue);

    if ((!isValidPhone && phoneValue !== undefined) || (!isValidZipCode && zipCodeValue !== undefined) || isEmpty(getValues(mapper))) {
      return error;
    }
    if (Array.isArray(mapper)) {
      for (const val of mapper) {
        if (isEmpty(getValues(val))) {
          return error;
        }
      }
    }
    return "";
  };

  const ButtonAction = () => (
    <div className="form-group" style={{ marginTop: 5 }}>
      {page === Object.keys(FORMS_MAPPER).length - 1 ? (
        <button onClick={_next} type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">
          Valider
        </button>
      ) : (
        <button onClick={_next} className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">
          Continuer
        </button>
      )}
    </div>
  );

  const ButtonArrowLeft = () => {
    if (page === 0 || stepLength === page) return null;
    return (
      <div onClick={_prev} style={{ display: "flex", flexDirection: "row", cursor: "pointer", alignItems: "center" }}>
        <BsArrowLeft />
        &nbsp;&nbsp;
        <span> Retour</span>
      </div>
    );
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        stepTitle: get(FORMS_MAPPER[page], "property", "null"),
        step: page,
      },
      dataLayerName: "PageDataLayer",
    });
  }, [page]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="axil-contact-form">
      <ButtonArrowLeft />
      {page !== 0 && <br />}
      <PageDisplay />
      <ButtonAction />
      {!isEmpty(errors) && <Alert variant={"danger"}>{errors}</Alert>}
    </form>
  );
};

export default FormMain;
