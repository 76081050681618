import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderTwo from "../common/header/HeaderTwo";
import SEO from "../common/SEO";
import BannerFour from "../component/banner/BannerFour";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import CtaSection from "../component/cta/CtaSection";
import FaqOne from "../component/faq/FaqOne";
import ProcessOne from "../component/process/ProcessOne";
import VideoTwo from "../component/video/VideoTwo";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import Testimonials from "./Testimonials";
//
const HomeStartup = () => {
  return (
    <>
      <SEO title="Acceuil" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderTwo />
        <BannerFour />
        <VideoTwo />
        <ProcessOne />
        <FaqOne />
        {/* <Testimonials /> */}
        {/* <CtaSection /> Ajouter la nouvelle section ici */}

        <CtaLayoutOne />
        <FooterOne />
      </main>
    </>
  );
};

export default HomeStartup;
