import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";

const societe = "Iso Eco";
const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Mentions légales" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Mentions légales" page="Mentions légales" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">Mentions légales et politique de confidentialité.</h5>
                  </div>
                  <p>
                    PROPRIETAIRE DU SITE :
                    <span>
                      &nbsp;Isolation ecologique:&nbsp;
                      <br />
                    </span>
                    N&ordm; SIRET :&nbsp;<span>852 695 352 00019</span>
                    <br />
                    DIRECTEUR DE LA PUBLICATION :&nbsp;<span>Isolation ecologique:&nbsp;</span>
                    <br />
                    SIEGE SOCIAL :&nbsp;<span>&nbsp;144 avenue du general patton MELUN</span>
                    <br />
                    Le prestataire assurant le stockage et permanent du site est la soci&eacute;t&eacute; TECH ADVISING.
                  </p>
                  <h4>Conception technique et d&eacute;veloppement :</h4>
                  <p>
                    EDITEUR :&nbsp;<span>TECH ADVISING</span>
                    <br />
                    SIEGE SOCIAL :<span>&nbsp;37 rue d&rsquo;Amsterdam 75008 PARIS &ndash; FRANCE</span>
                    <br />
                    MAIL :&nbsp;<span>webmaster@tech-advising.com</span>
                    <br />
                    SITE WEB :&nbsp;
                    <span>
                      <a href="http://www.tech-advising.com/" rel="noreferrer noopener" target="_blank">
                        www.tech-advising.com
                      </a>
                    </span>
                  </p>
                  <h4>Conditions g&eacute;n&eacute;rales de consultation</h4>
                  <p>
                    Le droit d&acute;utiliser les donn&eacute;es fournies sur le pr&eacute;sent site et leur t&eacute;l&eacute;chargement
                    sont contractuellement r&eacute;serv&eacute;s &agrave; la personne qui les consulte pour ses besoins personnels. En
                    cons&eacute;quence, celle-ci s&acute;interdit toute forme de vente, de commercialisation ou de cession &agrave; des
                    tiers.
                  </p>
                  <p>
                    Les droits de propri&eacute;t&eacute; intellectuelle sur les documents contenus dans le site et chacun des
                    &eacute;l&eacute;ments cr&eacute;&eacute;s pour ce site sont la propri&eacute;t&eacute; exclusive de&nbsp;
                    <span>Isolation ecologique:&nbsp;</span>. Celui-ci ne conc&eacute;dant aucune licence, ni aucun droit que celui de
                    consulter le site. La reproduction de tous documents publi&eacute;s sur le site est seulement autoris&eacute;e aux fins
                    exclusives d&acute;informations pour un usage personnel et priv&eacute;, toute reproduction et toute utilisation de
                    copies r&eacute;alis&eacute;es &agrave; d&acute;autres fins &eacute;tant express&eacute;ment interdites.
                    <br />
                    Le non-respect de cette interdiction constitue une contrefa&ccedil;on pouvant engager la responsabilit&eacute; civile et
                    p&eacute;nale du contrefacteur.
                  </p>
                  <p>
                    <span>Isolation ecologique:&nbsp;&nbsp;</span>s&acute;efforce d&acute;assurer au mieux de ses possibilit&eacute;s,
                    l&acute;exactitude et la mise &agrave; jour des informations diffus&eacute;es sur ce site, dont elle se r&eacute;serve
                    le droit de corriger, &agrave; tout moment et sans pr&eacute;avis, le contenu.
                  </p>
                  <h4>Responsabilit&eacute;</h4>
                  <p>
                    Les Utilisateurs du Site reconnaissent disposer de la comp&eacute;tence et des moyens n&eacute;cessaires pour
                    acc&eacute;der au Site et l&rsquo;utiliser. En particulier, les mat&eacute;riels et logiciels n&eacute;cessaires pour
                    l&rsquo;acc&egrave;s &agrave; Internet et au Site rel&egrave;vent de la seule responsabilit&eacute; des Utilisateurs.
                  </p>
                  <p>
                    <span>Isolation ecologique:&nbsp;&nbsp;</span>propose l&rsquo;acc&egrave;s au Site &agrave; titre gratuit et
                    d&eacute;cline par cons&eacute;quent toute responsabilit&eacute; en cas d&rsquo;interruption du Site, de survenance de
                    bogues informatiques, d&rsquo;erreurs d&rsquo;affichage ou de t&eacute;l&eacute;chargement et pour tous dommages
                    r&eacute;sultant d&rsquo;une intrusion frauduleuse d&rsquo;un tiers.
                  </p>
                  <p>
                    Les Utilisateurs utilisent le Site sous leur propre responsabilit&eacute;. En particulier, la responsabilit&eacute; de
                    la&nbsp;<span>Isolation ecologique:&nbsp;&nbsp;</span>ne pourra &ecirc;tre engag&eacute;e pour tous les
                    inconv&eacute;nients ou dommages li&eacute;s &agrave; l&rsquo;utilisation du r&eacute;seau de l&rsquo;Internet,
                    notamment une perte de donn&eacute;es, une rupture de service, une intrusion ext&eacute;rieure ou la pr&eacute;sence de
                    virus informatiques.
                  </p>
                  <h4>Loi applicable et juridiction comp&eacute;tente</h4>
                  <p>
                    Toute r&eacute;clamation relative au Site doit &ecirc;tre adress&eacute;e par courrier &eacute;lectronique
                    &agrave;&nbsp;<span>Isolation ecologique:&nbsp;&nbsp;</span>&agrave; l&rsquo;adresse postale suivante : 27 RUE DE NANTES
                    75019 PARIS
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
