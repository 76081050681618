import React from "react";
import IconCheck from "../check/IconCheck";

let styleCard = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  height: 60,
  alignItems: "center",
  border: "solid 2px",
  boxShadow: "0 4px 20px #0032501a",
  borderRadius: 10,
  padding: 10,
  marginTop: 10,
  marginBottom: 10,
  backgroundColor: "white",
};

const CardListItem = ({ text, iconPath, isCheck, handleChange, myKey }) => {
  //console.log(isCheck);
  return (
    <div className="btn-fill-primary" key={myKey} id={myKey} style={{ ...styleCard }} onClick={(e) => handleChange(e, text)}>
      <img src={iconPath} alt="calendar_icon" style={{ height: "2.5em" }} />
      <span>{text}</span>
      <IconCheck isCheck={isCheck} />
    </div>
  );
};

export default CardListItem;
