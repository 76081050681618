"use-sctrict";
import React, { useState } from "react";
import { POSTAL_CODE } from "../../utils/constants";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

const ZipCode = ({ setValue, getValues }) => {
  const [postalCode, setPostalCode] = useState("");

  const handleChange = (e, property) => {
    let value = e.target.value;
    setValue(property, value);
    setPostalCode(value);
  };
  return (
    <>
      <h5 className="center">Où se situe le logement concerné par les travaux ?</h5>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="form-group" style={{ width: "100%" }}>
          {/* <GoogleMaps setMyValue={setValue} /> */}
          <div className="form-group" style={{ width: "100%" }}>
            <label>Code Postal</label>
            <input
              autoFocus
              value={getValues(POSTAL_CODE)}
              type="number"
              className="form-control"
              name="postal-code"
              onChange={(e) => handleChange(e, POSTAL_CODE)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ZipCode;
