import React, { useState } from "react";
import ToggleButton from "react-bootstrap/ToggleButton";
import {
  FIRST_NAME,
  LAST_NAME,
  MADAME,
  MONSIEUR,
  SEXE,
} from "../../utils/constants";

const styles = {
  borderRadius: 5,
  width: "40%",
  backgroundColor: "white",
  boxShadow: "0 4px 20px #00325021",
  borderColor: "white",
  color: "#3b3b42",
};

const Step6 = ({ getValues, setValue }) => {
  const [object, setObject] = useState({
    [MONSIEUR]: false,
    [MADAME]: false,
  });
  const handleChangeSexe = (e, text) => {
    setObject((prevState) => {
      let result = prevState;
      Object.keys(result).forEach(function (key, index) {
        if (key === text) {
          result = { ...result, [key]: true };
        } else result = { ...result, [key]: false };
      });
      return result;
    });
    setValue(SEXE, text);
  };
  const handleChange = (e, property) => {
    let value = e.target.value;
    setObject((prevState) => {
      let result = prevState;
      Object.keys(result).forEach(function (key, index) {
        if (key === value) {
          result = { ...result, [key]: true };
        } else result = { ...result, [key]: false };
      });
      return result;
    });
    setValue(property, value);
  };

  return (
    <>
      <h5 className="center">Comment vous appelez-vous ?</h5>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <ToggleButton
            autoFocus
            className="mb-2"
            id="toggle-m"
            type="checkbox"
            variant="outline-secondary"
            checked={object[MONSIEUR] || getValues(SEXE) === MONSIEUR}
            style={{
              ...styles,
              border:
                (object[MONSIEUR] || getValues(SEXE) === MONSIEUR) &&
                "solid 2px",
            }}
            onChange={(e) => handleChangeSexe(e, MONSIEUR)}
          >
            {MONSIEUR}
          </ToggleButton>
          <ToggleButton
            className="mb-2"
            id="toggle-f"
            type="checkbox"
            variant="outline-secondary"
            checked={object[MADAME] || getValues(SEXE) === MADAME}
            style={{
              ...styles,
              border:
                (object[MADAME] || getValues(SEXE) === MADAME) && "solid 2px",
            }}
            onChange={(e) => handleChangeSexe(e, MADAME)}
          >
            {MADAME}
          </ToggleButton>
        </div>

        <div className="form-group" style={{ width: "100%" }}>
          <label>Prénom</label>
          <input
            autoFocus
            value={getValues(FIRST_NAME)}
            type="text"
            className="form-control"
            name="contact-prenom"
            onChange={(e) => handleChange(e, FIRST_NAME)}
          />
        </div>
        <div className="form-group" style={{ width: "100%" }}>
          <label>Nom</label>
          <input
            value={getValues(LAST_NAME)}
            type="text"
            className="form-control"
            name="contact-nom"
            onChange={(e) => handleChange(e, LAST_NAME)}
          />
        </div>
      </div>
    </>
  );
};
export default Step6;
