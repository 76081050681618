import React from "react";
import { useState } from "react";
import { DELAY_ANIMATION_CHECKBOX } from "../../utils/conf";
import { OWNER_STATUS_TYPE } from "../../utils/constants";
import Card from "../card/Card";
import CheckIcon from "../checkIcon/CheckIcon";

const firstItem = "Propriétaire";
const secondItem = "Locataire";

const OwnerOrTenant = ({ getValues, setValue, setPage, page }) => {
  const [object, setObject] = useState({
    [firstItem]: false,
    [secondItem]: false,
  });

  const handleChange = (e, text) => {
    if (text === secondItem) {
      setPage("Denied");
      return;
    }
    setValue(OWNER_STATUS_TYPE, text);
    setObject((prevState) => {
      let result = prevState;
      Object.keys(result).forEach(function (key, index) {
        if (key === text) {
          result = { ...result, [key]: true };
        } else result = { ...result, [key]: false };
      });
      return result;
    });
    setTimeout(() => {
      setPage(page + 1);
    }, DELAY_ANIMATION_CHECKBOX);
  };
  return (
    <Card title={"Dans ce logement, vous êtes :"} subTitle={" "}>
      <CheckIcon
        isCheck={object[firstItem] || getValues(OWNER_STATUS_TYPE) === firstItem}
        iconPath={process.env.PUBLIC_URL + "/icon/house/proprietaire.png"}
        text={firstItem}
        handleChange={handleChange}
      />

      <CheckIcon
        isCheck={object[secondItem] || getValues(OWNER_STATUS_TYPE) === secondItem}
        iconPath={process.env.PUBLIC_URL + "/icon/house/cles.png"}
        text={secondItem}
        handleChange={handleChange}
      />
    </Card>
  );
};
export default OwnerOrTenant;
