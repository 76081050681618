import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaEnvelopeOpen } from "react-icons/fa";
import axios from "axios";

const FooterOne = ({ parentClass }) => {
  const [email, setEmail] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

    if (!isValidEmail) {
      return;
    }
    setBtnDisabled(true);

    const ip = await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key=ce0de992545c4e1ca8ce4a5aa93abb49");
    let value = { emails: email, infos: ip.data };

    axios
      .post("https://sheet.best/api/sheets/a9552f86-26ec-4903-91f5-0dfee3135474", value)
      .then((response) => {
        //console.log(response);
        //RESET FORMS
      })
      .catch((e) => {
        console.error(e);
      });
  };
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="footer-widget ">
                <div className="footer-newsletter">
                  <h2 className="title">Abonnez Vous !</h2>
                  <form>
                    <div className="input-group">
                      <span className="mail-icon">
                        <FaEnvelopeOpen />{" "}
                      </span>
                      <input type="email" className="form-control" placeholder="Adresse email" onChange={(e) => setEmail(e.target.value)} />
                      <button
                        className="subscribe-btn"
                        style={{ backgroundColor: btnDisabled ? "green" : "" }}
                        type="submit"
                        onClick={sendEmail}
                        disabled={btnDisabled}
                      >
                        {!btnDisabled ? "S'abonner" : "Merci!"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. Tous Droits Reserves par <a href={process.env.PUBLIC_URL}>Isolation Ecologique</a>.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Mentions légales</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-use"}>Politique de confidentialité</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
