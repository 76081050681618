import React from "react";

const Card = ({ title, subTitle, children, titleStyle }) => {
  return (
    <div>
      <h5 style={{ margin: 0, ...titleStyle }}>{title}</h5>
      {subTitle ? (
        <div style={titleStyle}>
          <span style={{ fontSize: 13 }}>{subTitle}</span>
          <br></br>
          <br></br>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 5,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Card;
