import React from "react";
import FormMain from "../contact/FormMain";
import BrandItemNew from "../brand/BrandItemNew";
import CardIconList from "../card/CardIconList";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 2000,

  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const Data = [
  {
    image: "/icon/brands/telechargement-1.png",
    alt: "rge",
  },
  {
    image: "/icon/brands/cee.jpg",
    alt: "rge",
  },
  {
    image: "/icon/brands/Unknown.png",
    alt: "rge",
  },
  {
    image: "/icon/brands/agence.jpg",
    alt: "rge",
  },
  {
    image: "/icon/brands/eee.jpeg",
    alt: "rge",
  },
];

const BannerFour = () => {
  return (
    <div className="banner banner-style-6" style={{ height: "100%" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-7">
            <div className="banner-content">



            <h1 className="title">
  Installez votre{" "}
  <strong className="italic" style={{ color: "#5956E9" }}>
    Thermostat connecté
  </strong>{" "}
   et profitez d'une installation{" "}
  <strong className="italic" style={{ color: "#5956E9" }}>
    entièrement gratuite
  </strong>{" "}
  pour {" "}
  <strong className="italic" style={{ color: "#5956E9" }}>
    0€
  </strong> !
</h1>





              <CardIconList />
            </div>
          </div>

          {/* IMAGE BACKGROUND */}
          <div id="test" className="col-lg-6 col-xl-5">
            <div className="bg-white contact-form-box shadow-box">
              <FormMain />
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-19">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble" />
        </li>
        <li className="shape shape-2">
          <img src={process.env.PUBLIC_URL + "/images/others/line-7.png"} alt="Bubble" />
        </li>
      </ul>
      <BrandItemNew mediaWith={'992'} />
    </div>
  );
};

export default BannerFour;
