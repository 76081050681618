import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";

const TermsOfUse = () => {
  return (
    <>
      <SEO title="Politique de confidentialité" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderTwo />
        <BreadCrumbOne title="Politique de confidentialité" page="Politique de confidentialité" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <h2>Introduction</h2>
                  <p>
                    Devant le d&eacute;veloppement des nouveaux outils de communication, il est n&eacute;cessaire de porter une attention
                    particuli&egrave;re &agrave; la protection de la vie priv&eacute;e. C&rsquo;est pourquoi, nous nous engageons &agrave;
                    respecter la confidentialit&eacute; des renseignements personnels que nous collectons.
                  </p>
                  <h3>Collecte des renseignements personnels</h3>
                  <p>Nous collectons les renseignements suivants :</p>
                  <ul>
                    <li>Nom</li>
                    <li>Pr&eacute;nom</li>
                    <li>Adresse postale</li>
                    <li>Code postal</li>
                    <li>Adresse &eacute;lectronique</li>
                    <li>Num&eacute;ro de t&eacute;l&eacute;phone / t&eacute;l&eacute;copieur</li>
                    <li>Genre / Sexe</li>
                    <li>&Acirc;ge / Date de naissance</li>
                    <li>Profession</li>
                    <li>Revenus / ressources financi&egrave;res</li>
                    <li>Situation familiale</li>
                  </ul>
                  <p>
                    Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et gr&acirc;ce &agrave;
                    l&rsquo;interactivit&eacute; &eacute;tablie entre vous et notre site Web. Nous utilisons &eacute;galement, comme
                    indiqu&eacute; dans la section suivante, des fichiers t&eacute;moins et/ou journaux pour r&eacute;unir des informations
                    vous concernant.
                  </p>
                  <h3>Formulaires et interactivit&eacute; :</h3>
                  <p>Vos renseignements personnels sont collect&eacute;s par le biais de formulaire, &agrave; savoir :</p>
                  <ul>
                    <li>Formulaire d&rsquo;inscription au site Web</li>
                    <li>Sondage d&rsquo;opinion</li>
                    <li>Concours</li>
                  </ul>
                  <p>Nous utilisons les renseignements ainsi collect&eacute;s pour les finalit&eacute;s suivantes :</p>
                  <ul>
                    <li>Informations / Offres promotionnelles</li>
                    <li>Statistiques</li>
                    <li>Contact</li>
                    <li>Gestion du site Web (pr&eacute;sentation, organisation)</li>
                  </ul>
                  <p>
                    Vos renseignements sont &eacute;galement collect&eacute;s par le biais de l&rsquo;interactivit&eacute; pouvant
                    s&rsquo;&eacute;tablir entre vous et notre site Web et ce, de la fa&ccedil;on suivante:
                  </p>
                  <ul>
                    <li>Statistiques</li>
                    <li>Contact</li>
                    <li>Gestion du site Web (pr&eacute;sentation, organisation)</li>
                  </ul>
                  <p>Nous utilisons les renseignements ainsi collect&eacute;s pour les finalit&eacute;s suivantes :</p>
                  <ul>
                    <li>Forum ou aire de discussion</li>
                    <li>Commentaires</li>
                    <li>Correspondance</li>
                    <li>Informations ou pour des offres promotionnelles</li>
                  </ul>
                  <h3>Fichiers journaux et t&eacute;moins</h3>
                  <p>
                    Nous recueillons certaines informations par le biais de fichiers journaux (log file) et de fichiers t&eacute;moins
                    (cookies). Il s&rsquo;agit principalement des informations suivantes :
                  </p>
                  <ul>
                    <li>Adresse IP</li>
                    <li>Syst&egrave;me d&rsquo;exploitation</li>
                    <li>Pages visit&eacute;es et requ&ecirc;tes</li>
                    <li>Heure et jour de connexion</li>
                  </ul>
                  <p>Le recours &agrave; de tels fichiers nous permet :</p>
                  <ul>
                    <li>Am&eacute;lioration du service et accueil personnalis&eacute;</li>
                    <li>Profil personnalis&eacute; de consommation</li>
                    <li>Statistique</li>
                  </ul>
                  <h3>Droit d&rsquo;opposition et de retrait</h3>
                  <p>
                    Nous nous engageons &agrave; vous offrir un droit d&rsquo;opposition et de retrait quant &agrave; vos renseignements
                    personnels.
                    <br />
                    Le droit d&rsquo;opposition s&rsquo;entend comme &eacute;tant la possibilit&eacute; offerte aux internautes de refuser
                    que leurs renseignements personnels soient utilis&eacute;es &agrave; certaines fins mentionn&eacute;es lors de la
                    collecte.
                  </p>
                  <p>
                    Le droit de retrait s&rsquo;entend comme &eacute;tant la possibilit&eacute; offerte aux internautes de demander &agrave;
                    ce que leurs renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.
                  </p>
                  <p>
                    Pour pouvoir exercer ces droits, vous pouvez :<br />
                    Code postal : 144 avenue du general patton 77000 MELUN
                    <br />
                    Courriel : isoeco75@gmail.com
                    <br />
                    T&eacute;l&eacute;phone : 07 68 83 57 73
                    <br />
                    Section du site web : https://www.isolationecologique.com/
                  </p>
                  <h3>Droit d&rsquo;acc&egrave;s</h3>
                  <p>
                    Nous nous engageons &agrave; reconna&icirc;tre un droit d&rsquo;acc&egrave;s et de rectification aux personnes
                    concern&eacute;es d&eacute;sireuses de consulter, modifier, voire radier les informations les concernant.
                    <br />
                    L&rsquo;exercice de ce droit se fera :<br />
                    Code postal : 144 avenue du general patton 77000 MELUN
                    <br />
                    Courriel : isoeco75@gmail.com
                    <br />
                    T&eacute;l&eacute;phone : 07 68 83 57 72
                    <br />
                    Section du site web : https://www.isolationecologique.com/
                  </p>
                  <h3>S&eacute;curit&eacute;</h3>
                  <p>
                    Les renseignements personnels que nous collectons sont conserv&eacute;s dans un environnement s&eacute;curis&eacute;.
                    Les personnes travaillant pour nous sont tenues de respecter la confidentialit&eacute; de vos informations.
                    <br />
                    Pour assurer la s&eacute;curit&eacute; de vos renseignements personnels, nous avons recours aux mesures suivantes :
                  </p>
                  <ul>
                    <li>Protocole SSL (Secure Sockets Layer)</li>
                    <li>Gestion des acc&egrave;s &ndash; personne autoris&eacute;e</li>
                    <li>Sauvegarde informatique</li>
                    <li>Pare-feu (Firewalls)</li>
                  </ul>
                  <p>
                    Nous nous engageons &agrave; maintenir un haut degr&eacute; de confidentialit&eacute; en int&eacute;grant les
                    derni&egrave;res innovations technologiques permettant d&rsquo;assurer la confidentialit&eacute; de vos transactions.
                    Toutefois, comme aucun m&eacute;canisme n&rsquo;offre une s&eacute;curit&eacute; maximale, une part de risque est
                    toujours pr&eacute;sente lorsque l&rsquo;on utilise Internet pour transmettre des renseignements personnels.
                  </p>
                  <h3>L&eacute;gislation</h3>
                  <p>
                    Nous nous engageons &agrave; respecter les dispositions l&eacute;gislatives &eacute;nonc&eacute;es dans :<br />
                    FRAN&Ccedil;AISE L&Eacute;GISLATION
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default TermsOfUse;
