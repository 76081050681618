import React from "react";
import { LAST_NAME, SEXE } from "../../utils/constants";

const FinalStep = ({ getValues }) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={process.env.PUBLIC_URL + "/icon/house/check.png"}
          alt="Icon"
          style={{}}
        />
      </div>
      <br />
      <h5 style={{ textAlign: "center" }}>
        Bonne nouvelle {getValues(SEXE)} {getValues(LAST_NAME)}, vous êtes
        éligible aux aides de l'État !
      </h5>
      <h7
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Un conseiller vous contactera très bientôt afin de vérifier le montant
        de vos aides et de vous accompagner dans votre projet de rénovation
        énergétique !
      </h7>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <br></br>
        <div style={{ width: "100%" }}>
          <span style={{ fontSize: 9, textAlign: "center" }}>
            * En soumettant ce formulaire, j’accepte que les informations
            saisies soient exploitées dans le cadre de la relation commerciale
            qui peut en découler. Loi RGPD 2018 – Données sécurisées.
          </span>
        </div>
      </div>
    </>
  );
};
export default FinalStep;
