import React, { useState } from "react";

const Step7 = ({ getValues, setValue }) => {
  const { phone } = getValues();
  const [phoneState, setPhoneState] = useState("");

  const handleChange = (e, text) => {
    let value = e.target.value;
    setValue("phone", value);
    setPhoneState(value);
  };
  return (
    <>
      <h5 className="center">Quel est votre numéro de téléphone ?</h5>
      <h7>Vous acceptez d’être rappelé gratuitement par l’un de nos experts pour faire avancer votre projet.</h7>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="form-group" style={{ width: "100%" }}>
          <label>Téléphone</label>
          <input
            autoFocus
            type="number"
            className="form-control"
            name="contact-tel"
            style={{ backgroundColor: "white" }}
            onChange={handleChange}
            value={phoneState || phone}
          />
        </div>
      </div>
    </>
  );
};
export default Step7;
