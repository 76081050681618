import React from "react";

let styles = {
  display: "flex",
  justifyContent: "center",

  alignItems: "center",
  textAlign: "center",
  height: 80,
  width: 100,
  borderRadius: 10,

  flexDirection: "column",
  placeContent: "center",
  position: "relative",
  marginBottom: 10,
  border: "solid 2px",
  boxShadow: "0 4px 20px #0032501a",
  backgroundColor: "white",
};
const BorderCircle = () => {
  return (
    <div
      style={{
        margin: 5,
        border: "solid 1px",
        position: "absolute",
        right: 0,
        top: 0,
        height: 20,
        width: 20,
        borderRadius: 40,
      }}
    ></div>
  );
};
const IconCheck = () => {
  return (
    <img
      src={process.env.PUBLIC_URL + "/icon/house/check.png"}
      alt="Icon"
      style={{
        margin: 4,
        position: "absolute",
        right: 0,
        top: 0,
        height: 22,
      }}
    />
  );
};

const CheckIconSmall = ({ isCheck, iconPath, text, handleChange }) => {
  return (
    <>
      <div
        onClick={(e) => handleChange(e, text)}
        style={{
          ...styles,
        }}
      >
        {isCheck ? <BorderCircle /> : null}
        {isCheck ? <IconCheck /> : null}
        <img src={iconPath} alt="Icon" style={{}} />
        <span style={{ fontSize: 12, whiteSpace: "nowrap" }}>{text}</span>
      </div>
    </>
  );
};
export default CheckIconSmall;
