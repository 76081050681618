export const HOUSE_FIELD = "Maison";
export const POMPE_CHALEUR = "Pompe a chaleur";
export const GRANULER = "Chaudieres a granules";
export const ISOLATION = "Isoler mes facades";
export const VMC = "Ventiler mon logement";
export const APPART_FIELD = "Appartement";
export const MINUS_TWO_YEARS_FIELD = "Moins de 2 ans";
export const BETWEEN_TWO_FIFTEEN_FIELD = "Entre 2 ans et 15 ans";
export const YES = "Oui";
export const NO = "Non";
export const MORE_FIFTEEN_FIELD = "Plus de 15 ans";
export const FIOUL_FIELD = "Fioul";
export const ELECTRICITY_FIELD = "Électricite";
export const GAZ_FIELD = "Gaz";
export const BOIS_FIELD = "Chauffage au bois";
export const POMPE_CHALEUR_FIELD = "Pompe a chaleur";
export const OTHERS_FIELD = "Autre";
export const MONSIEUR = "Monsieur";
export const MADAME = "Madame";

export const MAPPING_FIELDS = {
  // STEP 1
  [HOUSE_FIELD]: "housingType",
  [APPART_FIELD]: "housingType",
  // STEP 2
  [MINUS_TWO_YEARS_FIELD]: "ageOfHouse",
  [BETWEEN_TWO_FIFTEEN_FIELD]: "ageOfHouse",
  [MORE_FIFTEEN_FIELD]: "ageOfHouse",
  // STEP 3
  [FIOUL_FIELD]: "typeOfEnergy",
  [ELECTRICITY_FIELD]: "typeOfEnergy",
  [GAZ_FIELD]: "typeOfEnergy",
  [BOIS_FIELD]: "typeOfEnergy",
  [POMPE_CHALEUR_FIELD]: "typeOfEnergy",
  [OTHERS_FIELD]: "typeOfEnergy",

  // STEP 4

  // STEP 5

  // STEP 6
};

// Property

export const SEXE = "sexe";
export const FIRST_NAME = "firstName";
export const LAST_NAME = "lastName";
export const OWNER_STATUS_TYPE = "ownerStatus";
export const TYPE_OF_ENERGY = "typeOfEnergy";
export const INSTALATION_TYPE = "instalationType";
export const POSTAL_CODE = "postalCode";
export const HOUSING_TYPE = "housingType";
export const AGE_OF_HOUSE_TYPE = "ageOfHouse";
export const PHONE_TYPE = "phone";
