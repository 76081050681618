import React from "react";

const IconCheck = ({ isCheck = false, style }) => {
  return (
    <>
      {isCheck ? (
        <img
          src={process.env.PUBLIC_URL + "/icon/house/check.png"}
          alt="Icon"
          style={{
            height: 20,
            width: 20,
            borderRadius: 50,
            ...style,
          }}
        />
      ) : (
        <div
          style={{
            border: "solid 1px",
            height: 20,
            width: 20,
            borderRadius: 50,
            ...style,
          }}
        />
      )}
    </>
  );
};

export default IconCheck;
