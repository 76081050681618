import React from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          <Link
            onClick={() =>
              window.open(
                "https://www.cotemaison.fr/devis-travaux-renovation/renovation-maison-nos-10-conseils-pour-la-reussir_32369.html",
                "_blank"
              )
            }
          >
            Conseils travaux <FaAngleDown />
          </Link>
        </li>
        <li className="menu-item-has-children">
          <Link onClick={() => window.open("https://www.economie.gouv.fr/particuliers/aides-renovation-energetique", "_blank")}>
            Aides financières <FaAngleDown />
          </Link>
        </li>
        {/* <div
          style={{
            paddingTop: "20px",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <img
            style={{ height: 80, width: 80 }}
            src={process.env.PUBLIC_URL + "/icon/brands/telechargement-1.png"}
            alt="Site Logo"
          />
          <img
            style={{ height: 80, width: 80 }}
            src={process.env.PUBLIC_URL + "/icon/brands/cee.jpg"}
            alt="Site Logo"
          />
          <img
            style={{ height: 80, width: 80 }}
            src={process.env.PUBLIC_URL + "/icon/brands/Unknown.png"}
            alt="Site Logo"
          />
        </div> */}
      </ul>
    </nav>
  );
};

export default Nav;
