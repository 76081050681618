import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FaqOneChildren = ({ children, title, eventKey }) => {
  return (
    <Accordion.Item eventKey={eventKey} key={eventKey}>
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>
        {children}
        <br></br>
        <button
          // onClick={executeScroll}
          // type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
          onClick={() => window.scrollTo(0, 0)}
        >
          Tester votre éligibilité
          {/* <sub>En 2 minutes</sub> */}
        </button>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default FaqOneChildren;
