import React from "react";
import Accordion from "react-bootstrap/Accordion";
import SectionTitle from "../../elements/section-title/SectionTitle";
import FaqOneChildren from "./FaqOneChildren";

const FaqOne = () => {
  return (
    <div className="section section-padding-equal bg-color-light faq-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-xl-4">
            <SectionTitle
              subtitle="Foire Aux Questions"
              title="Questions fréquentes sur le dispositif Coup de pouce CEE"
              description=""
              textAlignment="heading-left"
              textColor=""
            />
          </div>
          <div className="col-lg-7 col-xl-8">
            <div className="faq-accordion">
              <Accordion defaultActiveKey="1">
                <FaqOneChildren
                  title="❓ Qu’est-ce que le dispositif Coup de pouce CEE ?"
                  eventKey={1}
                  key={1}
                >
                  <p>
                    La prime Coup de pouce a été créée en 2017. Il s’agit d’une aide versée par les grands vendeurs d’énergies polluantes (carburants, gaz, fioul...) tels que Total, Engie ou encore Shell, dans le cadre de travaux résidentiels comme la rénovation d'ampleur des maisons individuelles ou des appartements, l’installation d’une chaudière à bois ou d'une pompe à chaleur, et la pose d’un système solaire combiné.
                  </p>
                  <p>
                    L’État oblige ces entreprises à contribuer financièrement à la rénovation énergétique en France, au moyen de sommes distribuées aux foyers ou aux syndicats de copropriétaires. Entre janvier 2019 et août 2020, plus d’un million de primes ont été versées pour un montant total dépassant 1,7 milliard d’euros.
                  </p>
                </FaqOneChildren>

                <FaqOneChildren
                  title="🙋 Qui peut profiter de la prime Coup de pouce « Pilotage connecté du chauffage pièce par pièce » ?"
                  eventKey={2}
                  key={2}
                >
                  <p>
                    Tous les ménages dont l’installation de chauffage est individuelle peuvent bénéficier de cette offre selon les conditions définies par la fiche d’opération standardisée BAR-TH-173. La mise en place doit être effectuée par un professionnel au sein d’un bâtiment résidentiel existant en France métropolitaine. Cette offre ne s’applique pas aux systèmes de chauffage collectifs.
                  </p>
                </FaqOneChildren>

                <FaqOneChildren
                  title="💡 Critères techniques et exigences à respecter"
                  eventKey={3}
                  key={3}
                >
                  <p>
                    La fourniture et la mise en place d’un système de régulation par programmation horaire pièce par pièce, sur une installation de chauffage neuve ou existante, pour les travaux engagés à compter du 1er décembre 2023 et jusqu’au 31 décembre 2024. Les travaux doivent être réalisés par un professionnel au sein d’un bâtiment résidentiel existant, et le système doit répondre aux normes EN 12098-1 et EN 12098-3.
                  </p>
                  <p>
                    Seules sont éligibles les opérations consistant en l’achat et la mise en place d’un système de régulation par programmation horaire pièce par pièce, de classe B ou de classe A conformément à la norme NF EN ISO 52120-1 pour les bâtiments résidentiels.
                  </p>
                </FaqOneChildren>

                <FaqOneChildren
                  title="💰 Quel est le montant de la prime Coup de pouce Thermostat ?"
                  eventKey={4}
                  key={4}
                >
                  <p>
                    Le montant d’incitation financière par logement doté est de 520 € multiplié par un facteur correctif de surface dépendant de la surface chauffée du logement. Par exemple, pour une surface chauffée de moins de 35 m², le facteur correctif est de 0,5, soit une prime de 260 €. Les primes augmentent avec la surface chauffée du logement.
                  </p>
                </FaqOneChildren>

                <FaqOneChildren
                  title="🔍 Quelle est la politique de contrôle ?"
                  eventKey={5}
                  key={5}
                >
                  <p>
                    Le professionnel réalisant l’opération doit prouver l’installation fonctionnelle d’un système de régulation par programmation horaire pièce par pièce. Les éléments de preuve doivent être conservés pendant 6 ans et incluent des photographies géolocalisées et horodatées de manière fiable de l’interface de pilotage et de chaque dispositif régulant l'émission des émetteurs de chaleur.
                  </p>
                </FaqOneChildren>

                <FaqOneChildren
                  title="🙋 Qui peut bénéficier de l’aide coup de pouce thermostat ?"
                  eventKey={6}
                  key={6}
                >
                  <p>
                    Tous les propriétaires occupants peuvent profiter de la prime « coup de pouce thermostat » à condition que leur système de chauffage soit individuel. Côté plafond de ressources.
                  </p>
                </FaqOneChildren>

              

                <FaqOneChildren
                  title="❓ Où faire sa demande de coup de pouce thermostat ?"
                  eventKey={8}
                  key={8}
                >
                  <p>
                    Pour recevoir la prime thermostat, il convient de suivre les indications de l’entreprise signataire choisie. Même si les démarches peuvent sensiblement différer, elles restent souvent similaires.
                  </p>
                </FaqOneChildren>

                <FaqOneChildren
                  title="💡 Qui verse la prime thermostat ?"
                  eventKey={9}
                  key={9}
                >
                  <p>
                    Comme toutes les primes CEE, le coup de pouce thermostat est versé par les entreprises signataires de la charte dédiée, généralement des vendeurs et fournisseurs d’énergie tels que Butagaz, Engie, etc.
                  </p>
                </FaqOneChildren>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" />
        </li>
        <li className="shape shape-2">
          <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" />
        </li>
        <li className="shape shape-4">
          <img src={process.env.PUBLIC_URL + "/images/others/poses-lady.png"} alt="Poses" />
        </li>
      </ul>
    </div>
  );
};

export default FaqOne;
